import { render, staticRenderFns } from "./PaymentStatus.vue?vue&type=template&id=4fb12ab3&scoped=true&"
import script from "./PaymentStatus.vue?vue&type=script&lang=js&"
export * from "./PaymentStatus.vue?vue&type=script&lang=js&"
import style0 from "./PaymentStatus.vue?vue&type=style&index=0&id=4fb12ab3&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fb12ab3",
  null
  
)

export default component.exports